/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import type { GatsbyBrowser } from 'gatsby'
import { AuthProvider } from './src/lib/authentication'
import { API_URL } from './src/constants'

/* eslint-disable-next-line import/prefer-default-export */
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <AuthProvider
    params={{
      APIUrl: API_URL || '',
    }}
  >
    {element}
  </AuthProvider>
)
